/* global $ */
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.module'
import properties from './properties.module'
import cities from './cities.module'
import areas from './areas.module'
import restaurants from './restaurants.module'

Vue.use(Vuex)

const state = {
  isInitialized: false,
}

// action types
export const INITIALIZE_PAGE = 'initializePage'

// mutation types
export const SET_INITIALIZED = 'setInitialized'

const getters = {
  isInitialized(state) {
    return state.isInitialized
  },
}

const actions = {
  [INITIALIZE_PAGE](context) {
    context.commit(SET_INITIALIZED)
  },
}

const mutations = {
  [SET_INITIALIZED](state) {
    state.isInitialized = true
    this._vm.$loadScript('/js/main.js').then(() => {
      $('[data-loader="circle-side"]').fadeOut() // will first fade out the loading animation
      $('#preloader').delay(350).fadeOut('slow') // will fade out the white DIV that covers the website.
      $('body').delay(350)
      $('.hero_in h1,.hero_in form').addClass('animated')
      $('.hero_single, .hero_in').addClass('start_bg_zoom')
      $(window).scroll()
    })
  },
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    properties,
    cities,
    areas,
    restaurants,
  },
})
