import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('@/views/Home.vue')
const PropertyListing = () => import('@/views/properties/PropertyListing')
const PropertyDetails = () => import('@/views/properties/PropertyDetails')
const PropertyTypeListing = () =>
  import('@/views/properties/PropertyTypeListing')
const CityPropertiesListing = () =>
  import('@/views/cities/CityPropertiesListing')
const Contact = () => import('@/views/Contact')
const AreaPropertiesListing = () =>
  import('@/views/areas/AreaPropertiesListing')
const AdvancedSearch = () => import('@/views/properties/AdvancedSearch')
const RestaurantDetails = () => import('@/views/restaurants/RestaurantDetails')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/smestaj-ulcinj-ada-bojana',
    name: 'PropertyListing',
    component: PropertyListing,
  },
  {
    path: '/listings/:type',
    name: 'PropertyTypeListing',
    component: PropertyTypeListing,
  },
  {
    path: '/properties/:slug',
    name: 'PropertyDetails',
    component: PropertyDetails,
  },
  {
    path: '/restaurants/:slug',
    name: 'RestaurantDetails',
    component: RestaurantDetails,
  },
  {
    path: '/city/:slug',
    name: 'CityPropertiesListing',
    component: CityPropertiesListing,
  },
  {
    path: '/area/:slug',
    name: 'AreaPropertiesListing',
    component: AreaPropertiesListing,
  },
  {
    path: '/advanced-search',
    name: 'AdvancedSearch',
    component: AdvancedSearch,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '*',
    redirect: '/lost',
  },
  {
    // the 404 route, when none of the above matches
    path: '/lost',
    name: 'Lost',
    component: () => import('@/views/Lost'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
