export const locale = {
  menu: {
    accommodation: 'Smeštaj',
    locations: 'Lokacije',
    contact: 'Kontakt',
  },
  footer: {
    follow_us: 'Zapratite nas',
    subscribe: 'Prijavi se',
  },
  validation: {
    email_missing: 'Molimo unesite email',
    newsletter_subscription_success: 'Uspešno ste se prijavili za newsletter!',
    newsletter_subscription_error:
      'Došlo je do greške prilikom slanja zahteva. Molimo Vas pokušajte ponovo.',
  },
  copyright: {
    terms: 'Uslovi korišćenja',
    privacy: 'Politika privatnosti',
  },
  property: {
    description: 'Opis smeštaja',
    images: 'Fotografije',
    location: 'Lokacija',
    submit: 'Pošalji zahtev',
    date: 'Datum...',
    guests: 'Broj osoba',
    total: 'Ukupno',
    full_name: 'Ime i prezime',
    email_address: 'Email adresa',
    phone: 'Telefon (xxx-xxxx-xxx)',
    details: 'Napomene',
  },
  searchBox: {
    title: 'Naziv smeštaja',
    location: 'Lokacija',
    position: 'Pozicija',
    type: 'Tip smeštaja',
    search: 'Pretraga',
  },
  slider: {
    offers: 'Ponuda',
    details: 'Detaljnije',
    deals: 'Celokupna ponuda',
  },
  propertyTypes: {
    all: 'Svi',
    hotels: 'Hoteli',
    river_houses: 'Sojenice',
    apartments: 'Apartmani',
    camps: 'Kampovi',
  },
}
