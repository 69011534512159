import ApiService from '@/services/api.service'

// action types
export const GET_AREAS = 'getAreas'
export const GET_AREA = 'getArea'

// mutation types
export const SET_AREAS = 'setAreas'
export const SET_AREA = 'setArea'
export const SET_ERROR = 'setError'

const state = {
  errors: null,
  areas: [],
  currentArea: {},
}

const getters = {
  areas(state) {
    return state.areas
  },
  currentArea(state) {
    return state.currentArea
  },
}

const actions = {
  [GET_AREAS](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/areas', '', payload)
        .then(({ data }) => {
          context.commit(SET_AREAS, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [GET_AREA](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/areas', payload.slug, payload)
        .then(({ data }) => {
          context.commit(SET_AREA, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_AREAS](state, areas) {
    state.areas = areas
    state.errors = {}
  },
  [SET_AREA](state, area) {
    state.currentArea = area
    state.errors = {}
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
