import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import ApiService from '@/services/api.service'
import { VERIFY_AUTH } from '@/store/auth.module'
import LoadScript from 'vue-plugin-load-script'
import 'bootstrap'
import 'owl.carousel'
import 'magnific-popup'
import 'theia-sticky-sidebar'
import 'flexslider'
import 'jquery.mmenu'
import 'hideshowpassword'
import 'ion-rangeslider'
import 'sticky-kit/dist/sticky-kit'
import BootstrapVue from 'bootstrap-vue'
import 'daterangepicker'
import { GET_CITIES } from '@/store/cities.module'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import VueGtag from 'vue-gtag'
import VueSocialSharing from 'vue-social-sharing'
import Vuelidate from 'vuelidate'
import i18n from '@/plugins/vue-i18n'

window.$ = window.jQuery = require('jquery')

const jQueryBridget = require('jquery-bridget')
const Isotope = require('isotope-layout')
// noinspection JSValidateTypes
jQueryBridget('isotope', Isotope, window.$)

const WOW = require('wowjs')
window.wow = new WOW.WOW({
  boxClass: 'wow',
  animateClass: 'animated',
  offset: 0,
  mobile: true,
  live: false,
  scrollContainer: null,
})

window.wow.init()

Vue.use(VueMeta)
// noinspection JSCheckFunctionSignatures
Vue.use(LoadScript)
Vue.use(BootstrapVue)
Vue.use(
  VueGtag,
  {
    config: { id: 'G-N43K2NSG2F' },
    appName: 'Ada Bojana For Me',
    pageTrackerScreenviewEnabled: true,
  },
  router
)
Vue.use(VueSocialSharing)
// noinspection JSCheckFunctionSignatures
Vue.use(Vuelidate)

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    getImageUrl(url) {
      return process.env.VUE_APP_STORAGE_PATH + url
    },
  },
})

ApiService.init()

router.beforeEach((to, from, next) => {
  Promise.all([
    store.dispatch(VERIFY_AUTH),
    store.dispatch(GET_CITIES, { only: ['title', 'slug'] }),
  ]).then(() => {
    next()
  })
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
