<template>
  <div id="sign-in-dialog" class="zoom-anim-dialog mfp-hide">
    <div class="small-dialog-header">
      <h3>Sign In</h3>
    </div>
    <form @submit.stop.prevent="onSubmit">
      <div class="sign-in-wrapper">
        <a href="javascript:" class="social_bt facebook">Login with Facebook</a>
        <a href="javascript:" class="social_bt google">Login with Google</a>
        <div class="divider"><span>Or</span></div>
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.form.email.$error }"
        >
          <label>Email</label>
          <input
            type="email"
            autocomplete="username"
            class="form-control"
            name="email"
            id="email"
            v-model.trim="$v.form.email.$model"
          />
          <i class="icon_mail_alt"></i>
        </div>
        <div
          class="form-group"
          :class="{ 'form-group--error': $v.form.password.$error }"
        >
          <label>Password</label>
          <input
            type="password"
            autocomplete="current-password"
            class="form-control"
            name="password"
            id="password"
            v-model.trim="$v.form.password.$model"
          />
          <i class="icon_lock_alt"></i>
        </div>
        <div class="clearfix add_bottom_15">
          <div class="checkboxes float-left">
            <label class="container_check"
              >Remember me
              <input type="checkbox" />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="float-right mt-1">
            <a id="forgot" href="javascript:void(0);">Forgot Password?</a>
          </div>
        </div>
        <div class="text-center">
          <input type="submit" value="Log In" class="btn_1 full-width" />
        </div>
        <div class="text-center">
          Don’t have an account? <a href="javascript:">Sign up</a>
        </div>
        <div id="forgot_pw">
          <div class="form-group">
            <label>Please confirm login email below</label>
            <input
              type="email"
              class="form-control"
              name="email_forgot"
              id="email_forgot"
            />
            <i class="icon_mail_alt"></i>
          </div>
          <p>
            You will receive an email containing a link allowing you to reset
            your password to a new preferred one.
          </p>
          <div class="text-center">
            <input type="submit" value="Reset Password" class="btn_1" />
          </div>
        </div>
      </div>
    </form>
    <!--form -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { LOGIN, LOGOUT } from '@/store/auth.module'
import { validationMixin } from 'vuelidate'
import { email, minLength, required } from 'vuelidate/lib/validators'

export default {
  name: 'SignInPopup',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }

      const email = this.$v.form.email.$model
      const password = this.$v.form.password.$model

      this.$store.dispatch(LOGOUT)

      this.$store
        .dispatch(LOGIN, { email, password })
        .then(() => window.location.replace('/smestaj-ulcinj-ada-bojana'))
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
}
</script>
