import ApiService from '@/services/api.service'

// action types
export const GET_CITIES = 'getCities'
export const GET_FEATURED_CITIES = 'getFeaturedCities'
export const GET_CITY = 'getCity'

// mutation types
export const SET_CITIES = 'setCities'
export const SET_FEATURED_CITIES = 'setFeaturedCities'
export const SET_CITY = 'setCity'
export const SET_ERROR = 'setError'

const state = {
  errors: null,
  cities: [],
  featuredCities: [],
  currentCity: {},
}

const getters = {
  cities(state) {
    return state.cities
  },
  featuredCities(state) {
    return state.featuredCities
  },
  currentCity(state) {
    return state.currentCity
  },
}

const actions = {
  [GET_CITIES](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/cities', '', payload)
        .then(({ data }) => {
          context.commit(SET_CITIES, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [GET_FEATURED_CITIES](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/cities', '', payload)
        .then(({ data }) => {
          context.commit(
            SET_FEATURED_CITIES,
            data.data.filter((city) => city.featured === 1)
          )
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [GET_CITY](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/cities', payload.slug, payload)
        .then(({ data }) => {
          context.commit(SET_CITY, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_CITIES](state, cities) {
    state.cities = cities
    state.errors = {}
  },
  [SET_FEATURED_CITIES](state, cities) {
    state.featuredCities = cities
    state.errors = {}
  },
  [SET_CITY](state, city) {
    state.currentCity = city
    state.errors = {}
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
