import ApiService from '@/services/api.service'
import { orderBy } from 'lodash'

// action types
export const GET_PROPERTIES = 'getProperties'
export const GET_FILTERED_PROPERTIES = 'getFilteredProperties'
export const GET_FEATURED_PROPERTIES = 'getFeaturedProperties'
export const GET_PROMOTED_PROPERTIES = 'getPromotedProperties'
export const GET_PROPERTY = 'getProperty'

// mutation types
export const SET_PROPERTIES = 'setProperties'
export const SET_FILTERED_PROPERTIES = 'setFilteredProperties'
export const SET_FEATURED_PROPERTIES = 'setFeaturedProperties'
export const SET_PROMOTED_PROPERTIES = 'setPromotedProperties'
export const SET_PROPERTY = 'setProperty'
export const SET_ERROR = 'setError'

const state = {
  errors: null,
  properties: [],
  featuredProperties: [],
  promotedProperties: [],
  currentProperty: {},
  filteredProperties: [],
}

const getters = {
  properties(state) {
    return state.properties
  },
  featuredProperties(state) {
    return state.featuredProperties
  },
  promotedProperties(state) {
    return state.promotedProperties
  },
  currentProperty(state) {
    return state.currentProperty
  },
  filteredProperties(state) {
    return state.filteredProperties
  },
}

const actions = {
  [GET_PROPERTIES](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/properties', '', payload)
        .then(({ data }) => {
          context.commit(SET_PROPERTIES, orderBy(data.data, 'price', 'desc'))
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [GET_FILTERED_PROPERTIES](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/properties', '', payload)
        .then(({ data }) => {
          const filteredProperties = data.data.filter(
            (property) => property[payload.attribute] === payload.value
          )
          context.commit(
            SET_FILTERED_PROPERTIES,
            orderBy(filteredProperties, 'price', 'desc')
          )
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [GET_FEATURED_PROPERTIES](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/properties', '', payload)
        .then(({ data }) => {
          const featuredProperties = data.data.filter(
            (property) => property.featured === 1
          )
          context.commit(
            SET_FEATURED_PROPERTIES,
            orderBy(featuredProperties, 'featured_position', 'asc')
          )
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [GET_PROMOTED_PROPERTIES](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/properties', '', payload)
        .then(({ data }) => {
          context.commit(
            SET_PROMOTED_PROPERTIES,
            data.data.filter((property) => property.status === 'promo')
          )
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [GET_PROPERTY](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/properties', payload.slug, payload)
        .then(({ data }) => {
          context.commit(SET_PROPERTY, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_PROPERTIES](state, properties) {
    state.properties = properties
    state.errors = {}
  },
  [SET_FILTERED_PROPERTIES](state, properties) {
    state.filteredProperties = properties
    state.errors = {}
  },
  [SET_FEATURED_PROPERTIES](state, properties) {
    state.featuredProperties = properties
    state.errors = {}
  },
  [SET_PROMOTED_PROPERTIES](state, properties) {
    state.promotedProperties = properties
    state.errors = {}
  },
  [SET_PROPERTY](state, property) {
    state.currentProperty = property
    state.errors = {}
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
