import ApiService from '@/services/api.service'
import { orderBy } from 'lodash'

// action types
export const GET_RESTAURANTS = 'getRestaurants'
export const GET_FEATURED_RESTAURANTS = 'getFeaturedRestaurants'
export const GET_RESTAURANT = 'getRestaurant'

// mutation types
export const SET_RESTAURANTS = 'setRestaurants'
export const SET_FEATURED_RESTAURANTS = 'setFeaturedRestaurants'
export const SET_RESTAURANT = 'setRestaurant'
export const SET_ERROR = 'setError'

const state = {
  errors: null,
  restaurants: [],
  featuredRestaurants: [],
  currentRestaurant: {},
}

const getters = {
  restaurants(state) {
    return state.restaurants
  },
  featuredRestaurants(state) {
    return state.featuredRestaurants
  },
  currentRestaurant(state) {
    return state.currentRestaurant
  },
}

const actions = {
  [GET_RESTAURANTS](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/restaurants', '', payload)
        .then(({ data }) => {
          context.commit(SET_RESTAURANTS, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [GET_FEATURED_RESTAURANTS](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/restaurants', '', payload)
        .then(({ data }) => {
          const featuredRestaurants = data.data.filter(
            (restaurant) => restaurant.featured === 1
          )
          context.commit(
            SET_FEATURED_RESTAURANTS,
            orderBy(featuredRestaurants, 'featured_position', 'asc')
          )
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [GET_RESTAURANT](context, payload) {
    return new Promise((resolve) => {
      ApiService.get('/api/restaurants', payload.slug, payload)
        .then(({ data }) => {
          context.commit(SET_RESTAURANT, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_RESTAURANTS](state, restaurants) {
    state.restaurants = restaurants
    state.errors = {}
  },
  [SET_FEATURED_RESTAURANTS](state, restaurants) {
    state.featuredRestaurants = restaurants
    state.errors = {}
  },
  [SET_RESTAURANT](state, restaurant) {
    state.currentRestaurant = restaurant
    state.errors = {}
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
