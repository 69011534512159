import ApiService from '@/services/api.service'
import JwtService from '@/services/jwt.service'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const UPDATE_PASSWORD = 'updateUser'

// mutation types
export const PURGE_AUTH = 'purgeAuth'
export const SET_AUTH = 'setUser'
export const SET_PASSWORD = 'setPassword'
export const SET_ERROR = 'setError'

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
}

const getters = {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
}

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.get('sanctum/csrf-cookie')
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    }).then(() => {
      return new Promise((resolve) => {
        ApiService.post('login', credentials)
          .then(({ data }) => {
            context.commit(SET_AUTH, credentials)
            resolve(data)
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors)
          })
      })
    })
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('login', credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.get('api/user')
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    } else {
      context.commit(PURGE_AUTH)
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return ApiService.put('password', payload).then(({ data }) => {
      context.commit(SET_PASSWORD, data)
      return data
    })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true
    state.user = user
    state.errors = {}
    JwtService.saveToken(state.user.email)
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    JwtService.destroyToken()
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
