export const locale = {
  menu: {
    accommodation: 'Accommodation',
    locations: 'Locations',
    contact: 'Contact',
  },
  footer: {
    follow_us: 'Follow us',
    subscribe: 'Subscribe',
  },
  validation: {
    email_missing: 'Please enter your email',
    newsletter_subscription_success:
      'You have successfully subscribed to the newsletter!',
    newsletter_subscription_error:
      'There was an error submiting your subscription to the newsletter. Please try again.',
  },
  copyright: {
    terms: 'Terms & Conditions',
    privacy: 'Privacy policy',
  },
  property: {
    description: 'Property description',
    images: 'Images',
    location: 'Location',
    submit: 'Submit your request',
    date: 'Date...',
    guests: 'Guests',
    total: 'Total',
    full_name: 'Full name',
    email_address: 'Email address',
    phone: 'Phone number (xxx-xxxx-xxx)',
    details: 'Details',
  },
  searchBox: {
    title: 'Accommodation name',
    location: 'Location',
    position: 'Position',
    type: 'Accommodation type',
    search: 'Search',
  },
  slider: {
    offers: 'Offers',
    details: 'Details',
    deals: 'All Deals',
  },
  propertyTypes: {
    all: 'All',
    hotels: 'Hotels',
    river_houses: 'River Houses',
    apartments: 'Apartments',
    camps: 'Camps',
  },
}
